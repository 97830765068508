import React, { useEffect, useState } from 'react';
import { BuildingType, CCTTemperatureType, CCTType, DesignSelectionClient, DesignSelections, EnumDescriptionClient, PDFClient } from '../../types';
import { Button } from '@mui/material';
import PDFSenderCard from './PDFSenderCard';

type Props = {
    designSelections: DesignSelections
}

const ResultSelectionCard = (props: Props): React.ReactElement => {
    const [colourTemperature, setColourTemperature] = useState<string>("");
    const [cCTType, setCCTType] = useState<string>("");
    const [buildingType, setBuildingType] = useState<string>("");
    const [showEmail, setShowEmail] = useState<boolean>(false);
    const path = window.location.href.replace(/\/+$/, '');

    //const [controlType, setControlType] = useState<string>("");
    //const [recessedSize, setRecessedSize] = useState<string>("");
     
    let fetchColourTemperature = function (designSelections: DesignSelections): void {
        if (designSelections.solution?.luminaire.luminaireColourTemperature === CCTTemperatureType.None || designSelections.buildingType === BuildingType.None) {
            return;
        }

        new EnumDescriptionClient(path, undefined).getCCTTemperatureType(designSelections.solution.luminaire.luminaireColourTemperature).then((displayName: string) => {
            setColourTemperature(displayName);
        })
        new EnumDescriptionClient(path, undefined).getCCTType(designSelections.solution.luminaire.luminaireColourType).then((displayName: string) => {
            setCCTType(displayName);
        })
        new EnumDescriptionClient(path, undefined).getBuildingType(designSelections.buildingType).then((displayName: string) => {
            setBuildingType(displayName);
        })

        //new EnumDescriptionClient(path, undefined).getControlType(designSelections.solution.luminaire.luminaireControlType).then((displayName: string) => {
        //    setControlType(displayName);
        //})
        //new EnumDescriptionClient(path, undefined).getRecessedSize(designSelections.solution.luminaire.luminaireRecessedSize).then((displayName: string) => {
        //    setRecessedSize(displayName);
        //})
    };

    useEffect(() => {
        fetchColourTemperature(props.designSelections);
    }, []);

    return (
        <>
            <div>
                {props.designSelections?.solution && colourTemperature !== "" && !showEmail && (
                    <div>
                        <h2 className="text-center">Project: {props.designSelections.projectName}</h2>
                        {/*<div className="d-flex justify-content-center mt-3 mb-3">*/}
                        {/*    <Button variant="contained" className="mx-auto" onClick={() => {*/}
                        {/*        setShowEmail(true);*/}
                        {/*    }}>Email PDF Report</Button>*/}
                        {/*</div>*/}
                        <h4 className="pb-2">Light Details</h4>
                        <div className="d-flex justify-content-between pb-5">
                            <div className="w-25">
                                <h6>Manufacturer</h6>
                                <p>{props.designSelections.solution.luminaire.manufactureName}</p>
                                <h6>Product Reference</h6>
                                <p>{props.designSelections.solution.luminaire.luminaireName}</p>
                                <h6>Light Source</h6>
                                <p>{cCTType}</p>
                                <h6>CCT</h6>
                                <p>{colourTemperature}</p>
                                <h6>Delivered Lumens</h6>
                                <p>{props.designSelections.solution.luminaire.deliveredLumens} lumens</p>
                                <h6>IP Rating</h6>
                                <p>{props.designSelections.solution.luminaire.ipRating}</p>
                                <h6>IK Rating</h6>
                                <p>{props.designSelections.solution.luminaire.ikRating}</p>
                                <h6>Housing Colour</h6>
                                <p>{props.designSelections.solution.luminaire.luminaireHousingColour}</p>
                                <h6>Housing Material</h6>
                                <p>{props.designSelections.solution.luminaire.luminaireHousingFinish}</p>
                            </div>
                            <div className="w-25">
                                <h6>Mount</h6>
                                <p>{props.designSelections.solution.luminaire.mount}</p>
                                <h6>Placement</h6>
                                <p>{props.designSelections.solution.luminaire.luminaireProductType}</p>
                                <h6>Driver</h6>
                                <p>{props.designSelections.solution.luminaire.driver}</p>
                                <h6>Control</h6>
                                <p>{props.designSelections.solution.luminaire.luminaireControlType}</p>
                            </div>
                            <div className="w-25 d-flex flex-column justify-content-start">
                                {props.designSelections.solution.luminaire.image1Path && (<img src={props.designSelections.solution.luminaire.image1Path} alt="Light Fitting" className="roomImage mb-4" /> )}
                                {props.designSelections.solution.luminaire.image2Path && (<img src={props.designSelections.solution.luminaire.image2Path} alt="Light Sketch" className="roomImage mb-4" /> )}
                            </div>
                        </div>

                        <h4 className="pb-2">Luminaire Details</h4>
                        <div className="d-flex justify-content-between">
                            <div className="w-25">
                                <h6>Number of Fittings</h6>
                                <p>{props.designSelections.solution.numberRequired}</p>
                                <h6>Rows</h6>
                                <p>{props.designSelections.solution.rowsRequired}</p>
                                <h6>Columns</h6>
                                <p>{props.designSelections.solution.columnsRequired}</p>
                                <h6>Total Power</h6>
                                <p>{props.designSelections.solution.totalSchemeWatts}W</p>
                                <h6>Current</h6>
                                <p>{((props.designSelections.solution.totalSchemeWatts / props.designSelections.solution.numberRequired) / 240).toFixed(2)}A</p>
                            </div>
                            <div className="w-25">
                                <h6>Height</h6>
                                <p>{props.designSelections.solution.luminaire.heightMM}</p>
                                <h6>Length</h6>
                                <p>{props.designSelections.solution.luminaire.lengthMM}</p>
                                <h6>Width</h6>
                                <p>{props.designSelections.solution.luminaire.widthMM}</p>
                                <h6>Weight</h6>
                                <p>{props.designSelections.solution.luminaire.weightKG}</p>
                                <h6>Wattage per m&sup2;</h6>
                                <p>{props.designSelections.solution.wattagePerArea} W/m&sup2;</p>
                            </div>
                            <div className="w-25">
                                {/*<Paper className="lightingLayout mb-4">*/}
                                {/*   <div className="lightingLayoutTop">*/}
                                {/*        <div className="square"></div>*/}
                                {/*        <div className="square"></div>*/}
                                {/*        <div className="square"></div>*/}
                                {/*    </div>*/}
                                {/*    <div className="lightingLayoutBottom">*/}
                                {/*        <div className="square"></div>*/}
                                {/*        <div className="square"></div>*/}
                                {/*        <div className="square"></div>*/}
                                {/*    </div>*/}
                                {/*</Paper>*/}
                                {/*    <img src={grid} alt="Grid" className="gridImage mb-4 d-none d-md-inline" />*/}
                            </div>
                        </div>
                        <div className="pb-5">
                            {/*    <img src={grid} alt="Grid" className="gridImage mt-4 d-md-none" />*/}
                        </div>
                        <h4 className="pb-2">Room Details</h4>
                        <div className="d-flex justify-content-between pb-5">
                            <div className="w-25">
                                <h6>Environment Type</h6>
                                <p>{buildingType} - General Office</p>
                                <h6>Room Length</h6>
                                <p>{props.designSelections.length}m</p>
                                <h6>Room Width</h6>
                                <p>{props.designSelections.width}m</p>
                                <h6>Room Height</h6>
                                <p>{props.designSelections.height}m</p>
                                <h6>Mounting Height</h6>
                                <p>{props.designSelections.height}m</p>
                                <h6>Ceiling Drop</h6>
                                <p>{props.designSelections.roomProperties.ceilingDrop}m</p>
                            </div>
                            <div className="w-25">
                                <h6>Ceiling Reflectance</h6>
                                <p>{props.designSelections.roomProperties.reflectanceCeiling}</p>
                                <h6>Wall Reflectance</h6>
                                <p>{props.designSelections.roomProperties.reflectanceWall}</p>
                                <h6>Floor Reflectance</h6>
                                <p>{props.designSelections.roomProperties.reflectanceFloor}</p>
                                <h6>Maintenance Factor</h6>
                                <p>{props.designSelections.roomProperties.maintenanceFactor}</p>
                            </div>
                            <div className="w-25"></div>
                        </div>

                        <h4 className="pb-2">Lighting Details</h4>
                        <div className="d-flex justify-content-between">
                            <div className="w-25">
                                <h6>Illuminance</h6>
                                <p>{props.designSelections.solution.luxLevel} lux</p>
                                <h6>Uniformity</h6>
                                <p>{props.designSelections.solution.luminaire.uniformity}</p>
                            </div>
                            <div className="w-25">
                                <h6>Target Illuminance</h6>
                                <p>{props.designSelections.roomProperties.targetIlluminance} lux</p>
                                <h6>Target Uniformity</h6>
                                <p>0.7</p>
                            </div>
                            <div className="w-25"></div>
                        </div>
                    </div>
                )}
                {showEmail && (
                    <PDFSenderCard designSelections={props.designSelections} setShowPdfSender={setShowEmail} />
                )}
            </div>
        </>
    );
}

export default ResultSelectionCard; 